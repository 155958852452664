import { IonRow, IonCol, IonCard, IonCardHeader, IonCardContent, IonSkeletonText } from "@ionic/react";

export default function LoadingCard() {
  return (
    <IonCard>
      <IonCardHeader>
        <IonSkeletonText animated={true} style={{ 'width': '80px' }}></IonSkeletonText>
      </IonCardHeader>
      <IonCardContent>
        <p>
          <IonSkeletonText animated={true} style={{ 'width': '60%' }}></IonSkeletonText>
        </p>
        <p>
          <IonSkeletonText animated={true} style={{ 'width': '30%' }}></IonSkeletonText>
        </p>
      </IonCardContent>
    </IonCard>
  )
}