import {
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRow, IonCol,
  IonButton,
  IonButtons,
  IonRefresher,
  IonRefresherContent,
  IonCard,
  IonAvatar,
  IonSkeletonText
} from '@ionic/react';
import '../Trainings/Home.css';

import { useState, useEffect } from 'react'

import { supabase } from '../../hooks/supabase';
import useUser from '../../hooks/user/getUser';
import DogDetailCard from '../../components/dogs/DogDetailCard';
import LoadingCard from '../../components/loading/LoadingCard';
import ProfileNav from './ProfileNav';
import ProfileCard from '../../components/profile/ProfileCard';
import UpdateProfileForm from './UpdateProfile/UpdateProfileForm';
import UpdateProfilePicture from './UpdateProfile/UpdateProfilePicture';



const Profile: React.FC = () => {
  const { user, setUser } = useUser();
  const [profile, setProfile] = useState();
  const [dogs, setDogs] = useState();
  const [loadedProfile, setLoadedProfile] = useState(false);
  const [loadedDogs, setLoadedDogs] = useState(false);
  const [editing, setEditing] = useState(false)
  const [updateProfilePicture, setUpdateProfilePicture] = useState(false);

  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
      getProfile();
      event.detail.complete();
    }, 2000);
  }



  useEffect(() => {
    getProfile();
    getDogs();
  }, [])


  async function getProfile() {
    try {
      const { data, error } = await supabase
        .from("profiles")
        .select(`
          id,
          username, 
          full_name,
          avatar_url
        `)
        .eq('id', user.id)
      if (error) throw error;
      if (data != null) {
        setProfile(data);
        console.log(data);
        setLoadedProfile(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  async function getDogs() {
    try {
      const { data, error } = await supabase
        .from("dogs")
        .select("*")
      if (error) throw error;
      if (data != null) {
        setDogs(data);
        console.log(data);
        setLoadedDogs(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  }


  //console.log(profile[0])

  return (

    <IonPage>

      <IonHeader>
        <IonToolbar>
          {!loadedProfile &&
            <IonSkeletonText animated={true} style={{ 'width': '80px' }}></IonSkeletonText>
          }
          {loadedProfile &&
            <ProfileNav profile={profile[0]} setEditing={setEditing} setUpdateProfilePicture={setUpdateProfilePicture} />
          }

        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonRefresher slot="fixed" pullFactor={0.5} pullMin={100} pullMax={200} onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        {!editing &&
          <IonGrid>
            {!loadedProfile &&
              <LoadingCard />
            }
            {loadedProfile &&
              <IonCard>

                <ProfileCard profile={profile[0]} />
                {!loadedDogs &&
                  <LoadingCard />
                }
                {loadedDogs &&
                  <>
                    <IonRow>

                      {dogs.map((dog) => (
                        <IonCol>
                          <DogDetailCard dog={dog} />
                        </IonCol>
                      ))}
                    </IonRow>
                  </>
                }
              </IonCard>
            }
          </IonGrid>
        }
        {editing &&
          <UpdateProfileForm editing={editing} setEditing={setEditing} profile={profile} />
        }
        {!updateProfilePicture &&
        <></>
        }
        {updateProfilePicture &&
          <UpdateProfilePicture setUpdateProfilePicture={setUpdateProfilePicture} user={user}/>
        }
      </IonContent>
    </IonPage>
  );
};

export default Profile;
