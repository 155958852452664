import { IonRow, IonCol, IonModal, IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, IonAvatar, IonLabel, IonImg } from "@ionic/react";
import { useState } from "react";

const ProfileCard: React.FC = (props) => {
  const profile = props.profile;
  const [viewImage, setViewImage] = useState(false);

  return (
    <>
    <IonRow>
      <IonCol>
        <IonAvatar onClick={() => setViewImage(true)}>
          <img src={profile.avatar_url} />
        </IonAvatar>
      </IonCol>
      <IonCol>
        <p>{profile.id}</p>
        <p>{profile.username}</p>
        <p>{profile.full_name}</p>
      </IonCol>
    </IonRow>
    
    <IonModal isOpen={viewImage}>
    <IonHeader>
          <IonToolbar>
           
            <IonButtons slot="end">
              <IonButton onClick={() => {
                setViewImage(false)
              }}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      <IonImg src={profile.avatar_url}></IonImg>
    </IonModal>
    </>
  )
}

export default ProfileCard;