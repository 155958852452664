import React, { useState } from "react";
import {
  IonRow,
  IonCol,
  IonItem,
  IonInput,
  IonButton,
  IonModal,
  IonToolbar,
  IonButtons,
  IonHeader,
  IonTitle,
  IonLabel,
  IonContent,
  IonSelect,
  IonSelectOption
} from "@ionic/react";
import { supabase } from "../../../hooks/supabase";
import useUser from "../../../hooks/user/getUser";
import { useHistory, useParams } from "react-router";



const AddCommandForm: React.FC = (props) => {

  const [editing, setEditing] = useState();
  const history = useHistory();
  const params = useParams();
  const { user, setUser } = useUser();
  const [isOpen, setIsOpen] = useState(true);

  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [dog, setDog] = useState();


  async function createCommand() {
    try {
      const { data, error } = await supabase
        .from("commands")
        .insert({
          user_id: user.id,
          name: name,
          description: description,
          dog_id: dog
        })
        .single()
      if (error) throw error;
      history.push("/commands");
    } catch (error) {
      console.log(error.message);
    }
  }

  return (
    <>
      <IonModal isOpen={isOpen}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>New Command</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => {
                setIsOpen(false);
                history.push("/commands");
              }}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel>Name</IonLabel>
                <IonInput
                  name="Name"
                  clearInput={true}
                  value={name}
                  onIonChange={(e: any) => {
                    setName(e.detail.value);
                  }}
                >
                </IonInput>
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel>Description</IonLabel>
                <IonInput
                  type="text"
                  name="description"
                  clearInput={true}
                  value={description}
                  onIonChange={(e: any) => {
                    setDescription(e.detail.value);
                  }}
                >
                </IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel>Dog</IonLabel>
                <IonSelect
                  aria-label="dog"
                  placeholder="Select dog"
                  interface="action-sheet"
                  onIonChange={(e) => {
                    setDog(e.detail.value)
                    console.log(dog);
                  }}
                >
                  <IonSelectOption value="9e35a081-e62e-4f16-b608-39e63381c46b">Louie</IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonButton expand="block" onClick={() => createCommand()}>Send</IonButton>

            </IonCol>
          </IonRow>
        </IonContent>
      </IonModal>

    </>
  );
}

export default AddCommandForm;