import { IonRow, IonCard, IonItem, IonAvatar, IonLabel } from "@ionic/react";
import { useHistory } from "react-router";

const DogDetailCard: React.FC = (props) => {
    const dog = props.dog;
    const history = useHistory();
    return (
        <IonRow>
            <IonCard onClick={() => {
                history.push("/dog/" + dog.id)
            }}>
                <IonItem>
                    <IonAvatar>
                        <img src={props.dog.avatar_url} />
                    </IonAvatar>
                    <IonLabel>{props.dog.name}</IonLabel>


                </IonItem>
                <IonItem>
                    <IonLabel>{props.dog.race}</IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>{props.dog.birthday}</IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>{props.dog.colour}</IonLabel>
                </IonItem>
            </IonCard>
        </IonRow>
    )
}

export default DogDetailCard;