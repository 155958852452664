import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonAvatar, IonButton, IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Trainings/Home';
import { supabase } from './hooks/supabase';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';


/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './App.css';
import './theme/variables.css';
import Login from './pages/Login/Login';
import { useState, useEffect } from 'react';
import Example from './pages/Trainings/AddTraining/AddTraining';
import TrainingDetails from './pages/Trainings/TrainingDetails/TrainingDetails';
import useUser from './hooks/user/getUser';
import GetSession from './hooks/user/getSession';
import { albumsOutline, mapOutline, megaphoneOutline, pawOutline, personCircleOutline, playCircle } from 'ionicons/icons';
import Commands from './pages/Commands/Commands';
import Profile from './pages/Profile/Profile';
import CommandDetails from './pages/Commands/CommandDetails/CommandDetails';
import NewTraining from './pages/Trainings/AddTraining/AddTraining';
import NewCommand from './pages/Commands/AddCommand/AddCommand';
import AddTrainingForm from './pages/Trainings/AddTraining/AddTraining';
import Dog from './pages/Dogs/Dogs';
import AddDogForm from './pages/Dogs/AddDog/AddDog';
import Geolocation from './pages/Trainings/components/GeoLocation';
import Contacts from './pages/Contacts/Contacts';
import PublicProfile from './pages/Profile/PublicProfile/PublicProfile';
import Feed from './pages/Feed/Feed';
// import UpdateCommand from './pages/Commands/UpdateCommand/UpdateCommandForm';

setupIonicReact();

const App: React.FC = () => {
  const [ isAuthed, setIsAuthed ] = useState(false);
  const { user, setUser } = useUser();

  useEffect(() => {

    getUserData();
    getProfile();
  }, []);

  async function getProfile() {
    try {
      const { data, error } = await supabase
      .from("profiles")
      .select()
      .eq('id', user.id)
      console.log(data[0])
      localStorage.setItem('profile', JSON.stringify(data[0]))
    } catch (error) {
      
    }
  }

  async function getUserData() {
    await supabase.auth.getSession().then((value) => {
      if(value.data?.session) {
        console.log(value.data.session);
       setIsAuthed(true);
      }
    })
  }

 
  console.log(user);

  const profile = JSON.parse(localStorage.getItem('profile'))
  console.log(profile);

  // const { session, getSession } = GetSession();
  // console.log(session);



  supabase.auth.onAuthStateChange((event, session) => {
    console.log(event, session)

    if(event === "SIGNED_IN") {
      setIsAuthed(true);

    }
    if(event === "SIGNED_OUT") {
      setIsAuthed(false);
    }

  });

  if(!isAuthed) {
    return <Login />
  };


  return(
  <IonApp>
    <IonReactRouter>
      <IonTabs>
      <IonRouterOutlet>
      <Route path="/" render={() => <Feed />} exact={true} />
        <Route path="/feed" render={() => <Feed />} exact={true} />
      <Route path="/training" render={() => <Home />} exact={true} />
          {/* <Route index element={<Home />} /> */}
        <Route path="/training/:id" render={() => <TrainingDetails />} exact={true} />
        <Route path="/training/add" render={() => <AddTrainingForm />} exact={true} />
      <Route path="/command" render={() => <Commands />} exact={true} />
        <Route index element={<Commands />} />
        <Route path="/command/:id" render={() => <CommandDetails />} exact={true} /> 
        <Route path="/command/add" render={() => <NewCommand />} exact={true} />
      <Route path="/location" render={() => <Geolocation />} exact={true} />
      <Route path="/profile/" render={() => <Profile />} exact={true} />
        <Route path="/profile/:id" render={() => <PublicProfile />} />
          <Route index element={<Profile />} />
          <Route path="/dog/:id" render={() => <Dog />} exact={true} />
          <Route path="/dog/add" render={() => <AddDogForm />} exact={true} />
        <Route path="/contacts" render={() => <Contacts />} exact={true} />
        
        <Route path="/login" render={() => <Login />} exact={true} />

     
      </IonRouterOutlet>

        <IonTabBar slot="bottom">

        <IonTabButton tab="feed" href="/feed">
            <IonIcon icon={albumsOutline} />
            <IonLabel>Feed</IonLabel>
          </IonTabButton>

          <IonTabButton tab="training" href="/training">
            <IonIcon icon={pawOutline} />
            <IonLabel>Trainings</IonLabel>
          </IonTabButton>

          <IonTabButton tab="commands" href="/command">
            <IonIcon icon={megaphoneOutline} />
            <IonLabel>Kommandos</IonLabel>
          </IonTabButton>

          <IonTabButton tab="location" href="/location">
            <IonIcon icon={mapOutline} />
            <IonLabel>Location</IonLabel>
          </IonTabButton>



          <IonTabButton tab="account" href="/profile">
            <IonIcon icon={personCircleOutline} />
            <IonLabel>Profil</IonLabel>
          </IonTabButton>

          
        </IonTabBar>
      </IonTabs>
    </IonReactRouter>
  </IonApp>
  )
};

export default App;
