import { IonAvatar, IonRow, IonItem, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonLabel } from '@ionic/react';
import { useState } from 'react';
//import { useNavigate } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

function CommandsCard(props) {
  //const navigate = useNavigate();
  const history = useHistory();
  const command = props.command;

  const [id, setId] = useState(command.id);
  const [name, setName] = useState(command.name);
  const [description, setDescription] = useState(command.description);
  const [dogs, setDogs] = useState([command.dogs])
  console.log(dogs);

  return (

    <IonCard onClick={() => {
      history.push("/command/" + command.id)
    }}>
      <IonCardHeader>
        <IonCardTitle>{command.name}</IonCardTitle>
      </IonCardHeader>

      <IonCardContent>
        <div>{command.description}</div>
        {dogs.map((dog) => (
          <IonRow>
            <IonCard>
              <IonItem>
                <IonAvatar>
                  <img src={dog.avatar_url} />
                </IonAvatar>
                <IonLabel>{dog.name}</IonLabel>
              </IonItem>
            </IonCard>
          </IonRow>
        ))}
      </IonCardContent>
    </IonCard>

  );
}

export default CommandsCard;