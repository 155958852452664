import { IonContent, IonSkeletonText, IonGrid, IonHeader, IonPage, IonTitle, IonToolbar, IonRow, IonCol, IonButton, IonButtons } from '@ionic/react';

import { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie';

import TrainingCard from '../components/trainingCard';

import { supabase } from '../../../hooks/supabase';
import { useParams, useHistory } from 'react-router';
import TrainingDetailsNav from './TrainignDetailsNav';
import useUser from '../../../hooks/user/getUser';
import LoadingCard from '../../../components/loading/LoadingCard';
import UpdateTrainingForm from '../UpdateTraining/UpdateTrainingForm';
import MapCard from '../components/MapCard';
import TrainingDetailsCard from '../components/trainingDetailsCard';


const TrainingDetails: React.FC = () => {
  const params = useParams();
  const history = useHistory();
  const [cookies, setCookies, removeCookie] = useCookies();
  const { user, setUser } = useUser();
  const [training, setTraining] = useState([]);
  const [editing, setEditing] = useState(false);
  const [loaded, setLoaded] = useState(false);
  //var decoded = jwt_decode(cookies.token);
  //const id = params.id;
  console.log(params.id)



  useEffect(() => {
    getTraining();
  }, [])

  async function deleteTraining() {
    try {
      const { data, error } = await supabase
        .from("trainings")
        .delete()
        .eq('id', params.id)
      if (error) throw error;
      history.push("/")
      console.log("deleted")
    } catch (error) {
      console.log(error.message);
    }
  }

  async function updateTraining() {
    console.log("updateTraining")
  }


  async function getTraining() {
    try {
      const { data, error } = await supabase
        .from("trainings")
        .select(`
        id,
        location, 
        time, 
        trainer,
        exercise,
        date,
        dogs (
          name,
          avatar_url
        ),
        training_type (
          type_name,
          icon
        )
        `)
        .eq('id', params.id)
      if (error) throw error;
      if (data != null) {
        setTraining(data);
        console.log(data);
        setLoaded(true);
      }
    } catch (error) {
      console.log(error.message);
    }

  }

  return (
    <IonPage>

      <IonHeader>
        {!loaded &&
          <IonSkeletonText animated={true} style={{ 'width': '80px' }}></IonSkeletonText>
        }
        {loaded &&
          <TrainingDetailsNav title={training} deleteTraining={deleteTraining} updateTraining={updateTraining} setEditing={setEditing} />
        }
      </IonHeader>

      <IonContent fullscreen>

        {!loaded &&
          <IonGrid>
            <IonRow>
              <IonCol>
                <LoadingCard />
              </IonCol>
            </IonRow>
          </IonGrid>
        }
        {loaded &&
          <IonGrid>
            {!editing &&
              <IonRow>
                {training.map((training) => (
                  
                  <IonCol>
                    <TrainingDetailsCard training={training} />
                 
                    </IonCol>
                  
                ))}
              </IonRow>
            }
            {editing &&
              // <update command={command}/>
              <UpdateTrainingForm training={training} editing={editing} />
            }
          </IonGrid>
        }
      </IonContent>
    </IonPage>
  );
};

export default TrainingDetails;
