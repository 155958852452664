import React, { useState } from "react";
import {
  IonRow,
  IonCol,
  IonItem,
  IonInput,
  IonButton,
  IonModal,
  IonToolbar,
  IonButtons,
  IonHeader,
  IonTitle,
  IonLabel
} from "@ionic/react";
import { supabase } from "../../../hooks/supabase";
import useUser from "../../../hooks/user/getUser";
import { useParams } from "react-router";
import { Camera, CameraResultType } from '@capacitor/camera';



const UpdateProfileForm: React.FC = (props) => {
  console.log(props);
  const [username, setUsername] = useState(props.profile[0].username);
  const [fullName, setFullName] = useState(props.profile[0].full_name);
  const params = useParams();
  const { user, setUser } = useUser();
  const [isOpen, setIsOpen] = useState(true);

  async function updateProfile() {
    try {
      const { data, error } = await supabase
        .from("profiles")
        .update({
          username: username,
          full_name: fullName,
        })
        .eq('id', params.id)
      if (error) throw error;
      console.log("inserted")
      //window.location.reload();
    } catch (error) {
      console.log(error.message);
    }
  }



  return (
    <>
      <IonModal isOpen={isOpen}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Modal</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => {
                setIsOpen(false)
                props.setEditing(false)
              }}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel>Username</IonLabel>
              <IonInput
                label="Username"
                labelPlacement="stacked"
                name="username"
                clearInput={true}
                value={username}
                onIonChange={(e: any) => {
                  console.log(e.detail.value)
                  setUsername(e.detail.value);
                  //console.log(name);
                }}



              >
              </IonInput>
            </IonItem>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel>Full Name</IonLabel>
              <IonInput
                type="text"
                name="fullName"
                clearInput={true}
                value={fullName}
                onIonChange={(e: any) => {
                  console.log(e.detail.value)
                  setFullName(e.detail.value);
                  //console.log(description);
                }}
              >
              </IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
        {/* <IonRow>
          <IonCol>
            <IonItem>
            <IonInput
                    name="name"
                    type='file'
                    clearInput={true}
                    //value={file}
                    onIonChange={(e: any) => {
                      console.log(e)
                      setAvatar(e.detail.value);
                    }}
                  >
                  </IonInput>
            </IonItem>
          </IonCol>
        </IonRow> */}
        <IonRow>
          <IonCol>
            <IonButton expand="block" onClick={() => updateProfile()}>Update</IonButton>

          </IonCol>
        </IonRow>
      </IonModal>

    </>
  );
}

export default UpdateProfileForm;