import { create } from 'zustand'
import { supabase } from '../supabase'

export const useFollowing = create((set) => (
    {
        following: false,
        setFollowing: (follower) => set((state) => ({}))
    }

))
