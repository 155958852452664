import { IonRow, IonCard, IonItem, IonAvatar, IonLabel } from "@ionic/react";
import { useHistory } from "react-router";

const DogAvatarCard: React.FC = (props) => {
    const history = useHistory();
    console.log(props.dog.id);

    return (
        <IonRow>
            <IonCard onClick={() => {
                history.push("/dog/" + props.dog.id)
            }}>
                <IonItem>
                    <IonAvatar>
                        <img src={props.dog.avatar_url} />
                    </IonAvatar>
                    <IonLabel>{props.dog.name}</IonLabel>
                </IonItem>
            </IonCard>
        </IonRow>
    )
}

export default DogAvatarCard;