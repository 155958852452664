import { 
  IonContent, 
  IonGrid, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonRow, IonCol, 
  IonButton, 
  IonButtons,
  IonRefresher,
  IonRefresherContent
 } from '@ionic/react';
import './Home.css';

import { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie';

import TrainingCard from './components/trainingCard';
import HomeNav from './homeNav';

import { supabase } from '../../hooks/supabase';
import useUser from '../../hooks/user/getUser';


const Home: React.FC = () => {
  const [cookies, setCookies, removeCookie] = useCookies();
  const { user, setUser } = useUser();
  const [trainings, setTrainings] = useState([]);

  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
      getTrainings();
      event.detail.complete();
    }, 2000);
  }



  useEffect(() => {
    getTrainings();
  }, [])


  async function getTrainings() {
    try {
      const { data, error } = await supabase
        .from("trainings")
        .select(`
          id,
          location, 
          time, 
          trainer,
          exercise,
          date,
          dogs (
            id,
            name,
            avatar_url
          ),
          training_type (
            type_name,
            icon
          )
        
        `)
        .order('date', {ascending: false})
      if (error) throw error;
      if (data != null) {
        setTrainings(data);
        console.log(data);
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  return (
    
    <IonPage>

      <IonHeader>
        <IonToolbar>
          <HomeNav />
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
      <IonRefresher slot="fixed" pullFactor={0.5} pullMin={100} pullMax={200} onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonGrid>

            {trainings.map((training) => (
            <IonRow>
              <IonCol>
                <TrainingCard training={training} />
              </IonCol>
              </IonRow>
            ))}


        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Home;
