import React, { useEffect, useInsertionEffect, useState } from "react";
import {
  IonRow,
  IonCol,
  IonItem,
  IonInput,
  IonButton,
  IonModal,
  IonToolbar,
  IonButtons,
  IonHeader,
  IonTitle,
  IonLabel,
  IonContent
} from "@ionic/react";
import { supabase } from "../../../hooks/supabase";
import useUser from "../../../hooks/user/getUser";
import { useParams } from "react-router";
import { Camera, CameraResultType } from '@capacitor/camera';



const UpdateProfilePicture: React.FC = (props) => {
  console.log(props);
  const params = useParams();
  const { user, setUser } = useUser();
  const [isOpen, setIsOpen] = useState(true);
  const [url, setUrl] = useState();
  const [path, setPath] = useState();

  useEffect(() => {
    uploadPicture();
  })

  // useEffect(() => {
  //   if (url) {
  //     downloadImage(url);
  //   }
  // }, [url]);

  useEffect(() => {
    setAvatarUrl();
  }, [url])

  const uploadPicture = async () => {
    try {
      const photo = await Camera.getPhoto({
        resultType: CameraResultType.DataUrl,
        allowEditing: true,
        quality: 80
      }).catch((e) => {
        console.log(e)
        if(e === 'Error: User cancelled photos app') {
          //props.setIsOpen(false)
          setIsOpen(false);
        }
      });

      const file = await fetch(photo.dataUrl)
        .then((res) => res.blob())
        .then(
          (blob) =>
            new File([blob], 'my-file', { type: `image/${photo.format}` })
        );

      const fileName = `${Math.random()}-${new Date().getTime()}.${
        photo.format
      }`;

      const pathName = `avatars/${props.user.id}/avatar/`;
      let { error: uploadError } = await supabase.storage
        .from('avatars')
        .upload(pathName+fileName, file);
        console.log(fileName);
        getPublicUrl(pathName, fileName);
      if (uploadError) {
        throw uploadError;
      }
      //onUpload(null, fileName);
    } catch (error) {
      console.log(error);
    }
  };

  async function getPublicUrl (pathName, fileName) {
    try {
      const {data } = supabase
      .storage
      .from('avatars')
      .getPublicUrl(pathName+fileName);
      setUrl(data.publicUrl)
      console.log(data);
    } catch (error) {
      
    }
  }

  async function setAvatarUrl() {
    try {
      const { data, error } = await supabase
        .from('profiles')
        .update({
          avatar_url: url
        })
        .eq('id', props.user.id)
        console.log("updated url");
      if (error) {
        throw error;
      }
      console.log(data);
      //const url = URL.createObjectURL(data);
      //setAvatarUrl(url);
    } catch (error) {
      console.log('Error downloading image: ', error.message);
    }
  };



  return (
    <>
     
    </>
  );
}

export default UpdateProfilePicture;