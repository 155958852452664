import { IonContent, IonGrid, IonHeader, IonPage, IonTitle, IonToolbar, IonRow, IonCol, IonButton, IonButtons, IonCardHeader, IonCardContent, IonSkeletonText, IonCard, IonItem, IonLabel, useIonModal, IonInput, HTMLIonInputElement, IonAvatar } from '@ionic/react';
import { OverlayEventDetail } from '@ionic/core/components';
import { useState, useEffect, useRef } from 'react'

import { supabase } from '../../../hooks/supabase';
import { useHistory, useParams } from 'react-router';
import TrainingDetailsNav from '../../Trainings/TrainingDetails/TrainignDetailsNav';
import useUser from '../../../hooks/user/getUser';
import CommandsCard from '../components/commandsCard';
import CommandDetailsNav from './CommandDetailsNav';
import LoadingCard from '../../../components/loading/LoadingCard';
import UpdateCommandForm from '../UpdateCommand/UpdateCommandForm';
import DogAvatarCard from '../../../components/dogs/DogAvatarCard';


const CommandDetails: React.FC = () => {
  const params = useParams();
  const { user, setUser } = useUser();
  const [command, setCommand] = useState([]);
  const history = useHistory();
  const [loaded, setLoaded] = useState(false);
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState("")
  const [description, setDescription] = useState("");
  const [test, setTest] = useState();

  useEffect(() => {
    getCommand();
    console.log("use effect")
  }, [])

  async function deleteCommand() {
    try {
      const { data, error } = await supabase
        .from("commands")
        .delete()
        .eq('id', params.id)
      if (error) throw error;
      history.push("/commands")
    } catch (error) {
      console.log(error.message);
    }
  }

  async function updateCommand() {
    try {
      const { data, error } = await supabase
        .from("commands")
        .update({
          dog_id: '9e35a081-e62e-4f16-b608-39e63381c46b',
          user_id: user.id,
          name: name,
          description: description,
        })
        .eq('id', params.id)
      if (error) throw error;
      console.log("inserted")
      //window.location.reload();
      setEditing(false);
    } catch (error) {
      console.log(error.message);
    }
  }

  async function getCommand() {
    try {
      const { data, error } = await supabase
        .from("commands")
        .select(`
          id,
          name,
          description, 
          dogs (
            name,
            avatar_url
          )
        `)
        .eq('id', params.id)
      if (error) throw error;
      if (data != null) {
        setCommand(data);
        console.log(data);
        setLoaded(true);
      }
    } catch (error) {
      console.log(error.message);
    }

  }

  console.log(command[0]);

  return (
    <IonPage>

      <IonHeader>
        {!loaded &&
          <IonSkeletonText animated={true} style={{ 'width': '80px' }}></IonSkeletonText>
        }
        {loaded &&
          <CommandDetailsNav command={command[0]} deleteCommand={deleteCommand} updateCommand={updateCommand} setEditing={setEditing} />
        }
      </IonHeader>

      <IonContent fullscreen>

        {!loaded &&
          <IonGrid>
            <IonRow>
              <IonCol>
                <LoadingCard />
              </IonCol>
            </IonRow>
          </IonGrid>
        }
        {loaded &&
          <IonGrid>
            {!editing &&
              <>
                <IonRow>

                  <IonCol>
                    <IonItem>
                      <IonGrid>
                        <IonRow>
                          <IonLabel>
                            <h1>{command[0].name}</h1>
                            <p>{command[0].description}</p>
                          </IonLabel>
                        </IonRow>
                        <DogAvatarCard dog={command[0].dogs} />

                      </IonGrid>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </>
            }
            {editing &&
              <UpdateCommandForm command={command} editing={editing} />
            }
          </IonGrid>
        }
      </IonContent>
    </IonPage>
  );
};

export default CommandDetails;
