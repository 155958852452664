import React, { useState } from "react";
import {
  IonRow,
  IonCol,
  IonItem,
  IonInput,
  IonButton,
  IonModal,
  IonToolbar,
  IonButtons,
  IonHeader,
  IonTitle,
  IonLabel,
  IonContent,
  IonSelect,
  IonSelectOption
} from "@ionic/react";
import { supabase } from "../../../hooks/supabase";
import useUser from "../../../hooks/user/getUser";
import { useHistory, useParams } from "react-router";



const AddDogForm: React.FC = (props) => {

  const [editing, setEditing] = useState();
  const history = useHistory();
  const params = useParams();
  const { user, setUser } = useUser();
  const [isOpen, setIsOpen] = useState(true);

  const [name, setName] = useState();
  const [race, setRace] = useState();
  const [birthday, setBirthday] = useState();
  const [colour, setColour] = useState();

  async function createDog() {
    try {
      const { data, error } = await supabase
        .from("dogs")
        .insert({
          user_id: user.id,
          name: name,
          race: race,
          birthday: birthday,
          colour: colour
        })
        .single()
      if (error) throw error;
      history.push("/profile/" + user.id);
    } catch (error) {
      console.log(error.message);
    }
  }

  return (
    <>
      <IonModal isOpen={isOpen}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>New Doggo</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => {
                setIsOpen(false);
                history.push("/profile/" + user.id);
              }}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel>Name</IonLabel>
              <IonInput
                label="Name"
                labelPlacement="stacked"
                name="name"
                clearInput={true}
                value={name}
                onIonChange={(e: any) => {
                  console.log(e.detail.value)
                  setName(e.detail.value);
                  console.log(name);
                }}



              >
              </IonInput>
            </IonItem>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel>Race</IonLabel>
              <IonInput
                type="text"
                name="race"
                clearInput={true}
                value={race}
                onIonChange={(e: any) => {
                  console.log(e.detail.value)
                  setRace(e.detail.value);
                }}
              >
              </IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel>Birthday</IonLabel>
              <IonInput
                type="date"
                name="birthday"
                clearInput={true}
                value={birthday}
                onIonChange={(e: any) => {
                  console.log(e.detail.value)
                  setBirthday(e.detail.value);
                }}
              >
              </IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel>Colour</IonLabel>
              <IonInput
                type="text"
                name="colour"
                clearInput={true}
                value={colour}
                onIonChange={(e: any) => {
                  console.log(e.detail.value)
                  setColour(e.detail.value);
                }}
              >
              </IonInput>
            </IonItem>
          </IonCol>
        </IonRow>

          <IonRow>
            <IonCol>
              <IonButton expand="block" onClick={() => createDog()}>Send</IonButton>

            </IonCol>
          </IonRow>
        </IonContent>
      </IonModal>

    </>
  );
}

export default AddDogForm;