import React, { useEffect, useState } from "react";
import {
  IonRow,
  IonCol,
  IonItem,
  IonInput,
  IonButton,
  IonModal,
  IonToolbar,
  IonButtons,
  IonHeader,
  IonTitle,
  IonLabel,
  IonContent,
  IonSelect,
  IonSelectOption
} from "@ionic/react";
import { supabase } from "../../../hooks/supabase";
import useUser from "../../../hooks/user/getUser";
import { useHistory, useParams } from "react-router";



const AddTrainingForm: React.FC = (props) => {

  const [editing, setEditing] = useState();
  const history = useHistory();
  const params = useParams();
  const { user, setUser } = useUser();
  const [isOpen, setIsOpen] = useState(true);
  const [loaded, setLoaded] = useState(false);

  const [types, setTypes] = useState();

  const [location, setLocation] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [trainer, setTrainer] = useState("");
  const [exercise, setExercise] = useState("");
  const [weather, setWeather] = useState("");
  const [duration, setDuration] = useState("");
  const [helper, setHelper] = useState("");
  const [dog, setDog] = useState("");
  const [notes, setNotes] = useState("");
  const [ name, setName] = useState();

  useEffect(() => {
    getTypes();
    
  }, [])


  function createName() {
    // const today = new Date();
    // console.log(today);
    console.log(time);
    //const exercise_time = time.getHours();
    console.log(exercise);
    //const exercise_type = exercise;

    // const exercise_name = exercise_type + " um " + exercise_time;
    // console.log(exercise_name);


  }


  async function getTypes() {
    try {
      const { data, error } = await supabase
        .from("training_type")
        .select(`
        id,
        type_name    
        `)
      if (error) throw error;
      if (data != null) {
        setTypes(data);
        setLoaded(true);
        console.log(data);
      }
    } catch (error) {
      console.log(error.message);
    }
  }


  async function createTraining() {
    try {
      const { data, error } = await supabase
        .from("trainings")
        .insert({
          location: location,
          user_id: user.id,
          date: date,
          time: time,
          trainer: trainer,
          training_type: exercise,
          weather: weather,
          duration: duration,
          helper: helper,
          dog_id: dog
        })
        .single()
      if (error) throw error;
      history.push("/");
    } catch (error) {
      console.log(error.message);
    }
  }

  return (
    <>
      <IonModal isOpen={isOpen}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Modal</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => {
                setIsOpen(false);
                setEditing(false)
              }}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel>Dog</IonLabel>
                <IonSelect
                  aria-label="dog"
                  placeholder="Select dog"
                  interface="action-sheet"
                  onIonChange={(e: any) => {
                    
                    console.log(e.detail.value);
                    setDog(e.target.value)

                  }}
                >
                  <IonSelectOption value="9e35a081-e62e-4f16-b608-39e63381c46b">Louie</IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel>Location</IonLabel>
                <IonInput
                  name="location"
                  clearInput={true}
                  value={location}
                  onIonChange={(e: any) => {
                    setLocation(e.detail.value);
                  }}
                >
                </IonInput>
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel>Date</IonLabel>
                <IonInput
                  type="date"
                  name="date"
                  clearInput={true}
                  value={date}
                  onIonChange={(e: any) => {
                    setDate(e.detail.value);
                  }}
                >
                </IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel>Time</IonLabel>
                <IonInput
                  name="time"
                  type="time"
                  clearInput={true}
                  value={time}
                  onIonChange={(e: any) => {
                    setTime(e.detail.value);
                  }}
                >
                </IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel>Trainer</IonLabel>
                <IonInput
                  name="trainer"
                  clearInput={true}
                  value={trainer}
                  onIonChange={(e: any) => {
                    setTrainer(e.detail.value);
                  }}
                >
                </IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel>Exercise</IonLabel>
                {/* <IonInput
                  name="exercise"
                  clearInput={true}
                  value={exercise}
                  onIonChange={(e: any) => {
                    setExercise(e.detail.value);
                  }}
                >
                </IonInput> */}
                {!loaded &&
                <></>
                }
                {loaded &&
                 <IonSelect
                  aria-label="type"
                  placeholder="Select type"
                  defaultValue=""
                  interface="action-sheet"
                  onIonChange={(e) => {
                    setExercise(e.detail.value)
                    console.log(e.detail.value);
                    //createName();
                  }}
                >
                  {types.map((type) => (
                    <IonSelectOption value={type.id}>{type.type_name}</IonSelectOption>
                  ))}
                </IonSelect>
                  }
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel>Weather</IonLabel>
                <IonInput
                  name="weather"
                  clearInput={true}
                  value={weather}
                  onIonChange={(e: any) => {
                    setWeather(e.detail.value);
                  }}
                >
                </IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel>Duration</IonLabel>
                <IonInput
                  name="duration"
                  clearInput={true}
                  value={duration}
                  onIonChange={(e: any) => {
                    setDuration(e.detail.value);
                  }}
                >
                </IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel>Name</IonLabel>
                <IonInput
                  name="helper"
                  clearInput={true}
                  value={helper}
                  onIonChange={(e: any) => {
                    setHelper(e.detail.value);
                  }}
                >
                </IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel>Notes</IonLabel>
                <IonInput
                  name="notes"
                  clearInput={true}
                  value={notes}
                  onIonChange={(e: any) => {
                    setNotes(e.detail.value);
                  }}
                >
                </IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton expand="block" onClick={() => createTraining()}>Send</IonButton>
              {/* <IonButton expand="block" onClick={() => createName()}>Send</IonButton> */}
            </IonCol>
          </IonRow>
        </IonContent>
      </IonModal>

    </>
  );
}

export default AddTrainingForm;