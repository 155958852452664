import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar, IonPage, IonContent, IonItem, IonLabel, IonInput, useIonModal } from '@ionic/react';
import { create, ellipsisHorizontal, ellipsisVertical, helpCircle, search,star, peopleOutline, logOutOutline, addCircleOutline, cogOutline } from 'ionicons/icons';
import { OverlayEventDetail } from '@ionic/core/components';
import { supabase } from '../../hooks/supabase';
import Login from '../Login/Login';
import AddTrainingForm from './AddTraining/AddTraining';

function HomeNav() {
  const history = useHistory();

  async function logout() {

    const { error } = await supabase.auth.signOut()

  }

  return (
    <IonToolbar>
        <IonButtons slot="secondary">
          <IonButton onClick={() => history.push("/training/add")}>
            <IonIcon slot="icon-only" icon={addCircleOutline}></IonIcon>
          </IonButton>
          <IonButton onClick={() => history.push("/contacts")}>
            <IonIcon slot="icon-only" icon={peopleOutline}></IonIcon>
          </IonButton>
        </IonButtons>
        <IonButtons slot="primary">
          <IonButton>
            <IonIcon slot="icon-only" icon={cogOutline} ></IonIcon>
          </IonButton>
          <IonButton onClick={() => logout()}>
            <IonIcon slot="icon-only" icon={logOutOutline} ></IonIcon>
          </IonButton>
        </IonButtons>
        <IonTitle>Feed</IonTitle>
      </IonToolbar>
  );
}
export default HomeNav;