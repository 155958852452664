import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar, IonPage, IonContent, IonItem, IonLabel, IonInput, useIonModal } from '@ionic/react';
import { create, ellipsisHorizontal, ellipsisVertical, helpCircle, search, star, peopleOutline, logOutOutline, addCircleOutline } from 'ionicons/icons';
import { OverlayEventDetail } from '@ionic/core/components';
import { supabase } from '../../hooks/supabase';
import Login from '../Login/Login';



function CommandsNav() {
  const history = useHistory();
  async function logout() {

    const { error } = await supabase.auth.signOut()

  }

  return (
    <IonToolbar>
      <IonButtons slot="secondary">
        <IonButton onClick={() => history.push("/command/add")}>
          <IonIcon slot="icon-only" icon={addCircleOutline}></IonIcon>
        </IonButton>
        <IonButton>
          <IonIcon slot="icon-only" icon={peopleOutline}></IonIcon>
        </IonButton>
      </IonButtons>
      <IonButtons slot="primary">
        <IonButton>
          <IonIcon slot="icon-only" ios={ellipsisHorizontal} md={ellipsisVertical}></IonIcon>
        </IonButton>
        <IonButton onClick={() => logout()}>
          <IonIcon slot="icon-only" icon={logOutOutline} ></IonIcon>
        </IonButton>
      </IonButtons>
      <IonTitle>Kommandos</IonTitle>
    </IonToolbar>
  );
}
export default CommandsNav;