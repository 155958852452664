import { IonButton, IonIcon, IonItem } from "@ionic/react"
import { pawOutline, thumbsDownOutline, thumbsUpOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import useUser from "../../hooks/user/getUser";
import { supabase } from "../../hooks/supabase";
import './LikeButton.css'

const LikeButton: React.FC = (props) => {

console.log(props);
    const { user, setUser } = useUser();
    const [liked, setLiked] = useState(false);
    const [likes, setLikes] = useState(props.training.likes); 
    const [likeId, setLikeId] = useState();
    const [justLiked, setJustLiked] = useState();

    useEffect(() => {
        checkForLikes();
    }, [setLikes])

    async function checkForLikes() {
        if (likes.some(e => e.liker_id === user.id)) {
            /* vendors contains the element we're looking for */
            setLiked(true)
          }
    }

  async function likeTraining() {
    try {
        const {data,error} = await supabase
        .from("likes")
        .upsert({
            training_id: props.training.id,
            liker_id: user.id
        })
        .select()
        console.log(data);
        setJustLiked(data[0]);
        setLikes(likes => [...likes, data[0]]);
        setLiked(true);
    } catch (error) {
        console.log(error);
    }
  }

  async function unlikeTraining() {

    const like_id = likes.find(e => e.liker_id === user.id);
    console.log(like_id);

    setLikes((current) =>
    current.filter((likes) => likes.id === like_id)
    );
      setLiked(false);

      try {
        const {data,error} = await supabase
        .from("likes")
        .delete()
        .eq('id', like_id.id )
    } catch (error) {
        console.log(error);
    }
  }

    return(
        <>
        <IonItem>{likes.length} Likes</IonItem>
        {!liked &&
            <IonButton onClick={() => likeTraining()}>
                <IonIcon slot="start" icon={pawOutline}></IonIcon>
            Like
        </IonButton>
        }
        {liked &&
            <IonButton secondary onClick={() => unlikeTraining()}>
                <IonIcon slot="start" class="unlikeButton" icon={pawOutline}></IonIcon>
            Unlike
        </IonButton>
        }
    </>
    )
}

export default LikeButton;