import { IonContent, IonGrid, IonHeader, IonPage, IonTitle, IonToolbar, IonRow, IonCol, IonButton, IonButtons } from '@ionic/react';
import { useState, useEffect } from 'react'

import { supabase } from '../../hooks/supabase';
import useUser from '../../hooks/user/getUser';
import CommandsCard from './components/commandsCard';
import CommandsNav from './commandsNav';


const Commands: React.FC = () => {
  const { user, setUser } = useUser();
  const [commands, setCommands] = useState([]);


  useEffect(() => {
    getCommands();
    getDogs();
  }, [])

  async function getDogs() {
    try {
      const { data, error } = await supabase 
        .from("dogs")
        .select(`
          id,
          name,
          commands (
            id,
            name
          )
        `)
      if(error) throw error;
      console.log(data);
    } catch (error) {
    console.log(error);
  }
}


  async function getCommands() {
    try {
      const { data, error } = await supabase
        .from("commands")
        .select(`
          id,
          name,
          description, 
          dogs (
            name,
            avatar_url
          )
        `)
        .order('name', { ascending: true })
        .eq('user_id', user.id)
      if (error) throw error;
      if (data != null) {
        setCommands(data);
        console.log(data);
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  return (
    <IonPage>

      <IonHeader>
        <IonToolbar>
          <CommandsNav />
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonGrid>
       
            {commands.map((command) => (
                 <IonRow>
              <IonCol>
                <CommandsCard command={command} />
              </IonCol>
              </IonRow>
            ))}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Commands;
