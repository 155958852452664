import React, { useState } from "react";
import {
  IonRow,
  IonCol,
  IonItem,
  IonInput,
  IonButton,
  IonModal,
  IonToolbar,
  IonButtons,
  IonHeader,
  IonTitle
} from "@ionic/react";
import { supabase } from "../../../hooks/supabase";
import useUser from "../../../hooks/user/getUser";
import { useParams } from "react-router";



const UpdateTrainingForm: React.FC = (props) => {

  const [editing, setEditing] = useState();
  //   const [name, setName] = useState(props.training[0].);
  //   const [description, setDescription] = useState(props.command[0].description);
  const params = useParams();
  const { user, setUser } = useUser();
  const [isOpen, setIsOpen] = useState(true);

  const [location, setLocation] = useState(props.training[0].location);
  const [date, setDate] = useState(props.training[0].date);
  const [time, setTime] = useState(props.training[0].time);
  const [trainer, setTrainer] = useState(props.training[0].trainer);
  const [exercise, setExercise] = useState(props.training[0].exercise);
  const [weather, setWeather] = useState(props.training[0].weather);
  const [duration, setDuration] = useState(props.training[0].duration);
  const [helper, setHelper] = useState(props.training[0].helper);
  const [notes, setNotes] = useState(props.training[0].notes);


  async function updateTraining() {
    try {
      const { data, error } = await supabase
        .from("trainings")
        .update({
          location: location,
          user_id: user.id,
          date: date,
          time: time,
          trainer: trainer,
          exercise: exercise,
          weather: weather,
          duration: duration,
          helper: helper,
          notes: notes
        })
        .eq('id', params.id)
      if (error) throw error;
      console.log("inserted")
      window.location.reload();
    } catch (error) {
      console.log(error.message);
    }
  }

  return (
    <>
      <IonModal isOpen={isOpen}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Modal</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => {
                setIsOpen(false);
                setEditing(false)
              }}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonRow>
          <IonCol>
            <IonItem>
              {/* <IonLabel position="floating">Name</IonLabel> */}
              <IonInput
                label="Name"
                labelPlacement="stacked"
                name="location"
                clearInput={true}
                value={location}
                onIonChange={(e: any) => {
                  setLocation(e.detail.value);
                }}
              >
              </IonInput>
            </IonItem>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            <IonItem>
              <IonInput
                type="date"
                name="date"
                clearInput={true}
                value={date}
                onIonChange={(e: any) => {
                  setDate(e.detail.value);
                }}
              >
              </IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              {/* <IonLabel position="floating">Name</IonLabel> */}
              <IonInput
                label="Time"
                labelPlacement="stacked"
                name="time"
                clearInput={true}
                value={time}
                onIonChange={(e: any) => {
                  setTime(e.detail.value);
                }}
              >
              </IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              {/* <IonLabel position="floating">Name</IonLabel> */}
              <IonInput
                label="Trainer"
                labelPlacement="stacked"
                name="trainer"
                clearInput={true}
                value={trainer}
                onIonChange={(e: any) => {
                  setTrainer(e.detail.value);
                }}
              >
              </IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              {/* <IonLabel position="floating">Name</IonLabel> */}
              <IonInput
                label="Exercise"
                labelPlacement="stacked"
                name="exercise"
                clearInput={true}
                value={exercise}
                onIonChange={(e: any) => {
                  setExercise(e.detail.value);
                }}
              >
              </IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              {/* <IonLabel position="floating">Name</IonLabel> */}
              <IonInput
                label="Weather"
                labelPlacement="stacked"
                name="weather"
                clearInput={true}
                value={weather}
                onIonChange={(e: any) => {
                  setWeather(e.detail.value);
                }}
              >
              </IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              {/* <IonLabel position="floating">Name</IonLabel> */}
              <IonInput
                label="Duration"
                labelPlacement="stacked"
                name="duration"
                clearInput={true}
                value={duration}
                onIonChange={(e: any) => {
                  setDuration(e.detail.value);
                }}
              >
              </IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              {/* <IonLabel position="floating">Name</IonLabel> */}
              <IonInput
                label="Helper"
                labelPlacement="stacked"
                name="helper"
                clearInput={true}
                value={helper}
                onIonChange={(e: any) => {
                  setHelper(e.detail.value);
                }}
              >
              </IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              {/* <IonLabel position="floating">Name</IonLabel> */}
              <IonInput
                label="Notes"
                labelPlacement="stacked"
                name="notes"
                clearInput={true}
                value={notes}
                onIonChange={(e: any) => {
                  setNotes(e.detail.value);
                }}
              >
              </IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonButton expand="block" onClick={() => updateTraining()}>Send</IonButton>

          </IonCol>
        </IonRow>
      </IonModal>

    </>
  );
}

export default UpdateTrainingForm;