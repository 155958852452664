
import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar, IonPage, IonContent, IonItem, IonLabel, IonInput, useIonModal, IonBackButton, useIonActionSheet } from '@ionic/react';
import { create, ellipsisHorizontal, ellipsisVertical, helpCircle, search, star, peopleOutline, logOutOutline, addCircleOutline } from 'ionicons/icons';
import { OverlayEventDetail } from '@ionic/core/components';



function ProfileNav(props) {

  //const title = props.title;
  console.log(props);
  const [present] = useIonActionSheet();
  const [result, setResult] = useState<OverlayEventDetail>();
  const history = useHistory();
  const command = props.profile;


  return (
    <IonToolbar>

      <IonTitle>Profile</IonTitle>
      <IonButtons slot="primary">
        <IonButton
          onClick={() =>
            present({
              header: 'Example header',
              subHeader: 'Example subheader',
              buttons: [
                {
                  text: 'Add Doggo',
                  data: {
                    action: 'add',
                  },
                },
                {
                  text: 'Update Profile',
                  data: {
                    action: 'update',
                  },
                },
                {
                  text: 'Update Profile Picture',
                  data: {
                    action: 'update picture',
                  },
                },
                {
                  text: 'Cancel',
                  role: 'cancel',
                  data: {
                    action: 'cancel',
                  },
                },
              ],
              onDidDismiss: ({ detail }) => {
                console.log(detail);
                if (detail.data.action === 'add') {
                  console.log(detail.data.action);
                  history.push("/dog/add");
                }

                if (detail.data.action === 'update') {
                  // props.updateCommand();
                  console.log("Update")
                  props.setEditing(true);
                }

                if (detail.data.action === 'update picture') {
                  console.log("update picture")
                  props.setUpdateProfilePicture(true);
                }
              },
            })
          }
        >
          <IonIcon slot="icon-only" icon={ellipsisHorizontal}></IonIcon>
        </IonButton>

      </IonButtons>
    </IonToolbar>

  );
}
export default ProfileNav;