import { IonAvatar, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonItem, IonLabel } from "@ionic/react"
import DogAvatarCard from "../dogs/DogAvatarCard";
import LikeButton from "./LikeButton";
import { useEffect, useState } from "react";
import { supabase } from "../../hooks/supabase";
import useUser from "../../hooks/user/getUser";
import { useHistory } from "react-router";
import CommentSection from "./CommentSection";
import ShareButton from "./ShareButton";


const TrainingCardFeed: React.FC = (props) => {

    const history = useHistory();

    return (
        // <IonCard onClick={() => history.push("/feed/" + props.training.id)}>
        <IonCard>
            <IonCardHeader>
                <IonAvatar>
                    <img src={props.training.profiles.avatar_url} />
                </IonAvatar>
                <IonItem>{props.training.profiles.full_name}</IonItem>
                <IonCardTitle>
                    {props.training.location}
                </IonCardTitle>
                <IonCardSubtitle>
                    {props.training.training_type.type_name}
                </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
                <DogAvatarCard dog={props.training.dogs} />
                <LikeButton training={props.training} />
                <CommentSection training={props.training} />
                {/* <ShareButton /> */}
            </IonCardContent>

        </IonCard>
    )
}

export default TrainingCardFeed;