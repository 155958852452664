import { IonButton, IonContent, IonHeader, IonItem, IonLabel, IonList, IonGrid, IonPage, IonSearchbar, IonToolbar, IonAvatar } from "@ionic/react";
import { useState } from "react";
import { supabase } from "../../hooks/supabase";
import HomeNav from "../Trainings/homeNav";
import { useHistory } from "react-router";

const Contacts: React.FC = () => {

    const [fullname, setFullname] = useState("");
    const [ users, setUsers ] = useState([]);
    const history = useHistory();

    async function searchUsers() {
        try {
            const { data, error } = await supabase
                .from("profiles")
                .select(`
                id,
                full_name,
                avatar_url
            `)
                .eq('full_name', fullname)
            if (error) throw error;
            if (data != null) {
                console.log(data);
                setUsers(data);
                console.log(users);
            }

        } catch (error) {
            console.log(error);
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HomeNav />
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonSearchbar
                    onIonChange={(e: any) => (
                        console.log(e.detail.value),
                        setFullname(e.detail.value)
                    )}
                    onIonClear={(e: any) => (
                        //setFullname(e.detail.value),
                        searchUsers()
                    )}
                >
                    <IonButton onClick={() => searchUsers()}>Search</IonButton>
                </IonSearchbar>
                <IonGrid>
                    <IonList>
                        {users.map((user) => (
                            <IonItem onClick={() => history.push("/profile/" + user.id)}>
                                <IonAvatar>
                                    <img src={user.avatar_url} />
                                </IonAvatar>
                                <IonLabel>{user.full_name}</IonLabel>
                            </IonItem>
                        ))}
                    </IonList>
                </IonGrid>
            </IonContent>
        </IonPage>

    )
}

export default Contacts;