import { IonButton } from "@ionic/react";
import { useState } from "react";
import { supabase } from "../../../hooks/supabase";
import { useParams } from "react-router";
import useUser from "../../../hooks/user/getUser";

const UnfollowUser: React.FC = (props) => {
    const following = props.following;
    console.log(following);
    const params = useParams();
    const { user, setUser } = useUser();

    async function unfollowUser() {
        try {
            const { data, error } = await supabase
            .from("follows")
            .delete()
            .eq('id', props.connection[0].id) 
            if(error) throw error;
            console.log(data)
            props.setFollowing(false)
        } catch (error) {
            console.log(error)
        }
    }

    if ( params.id != user.id ) {
    return (
        <IonButton onClick={() => (
            unfollowUser()
            
        )}>
            Unfollow
        </IonButton>

    );
        }
};

export default UnfollowUser;