import { IonPage, IonCard, IonGrid, IonRow, IonCol, IonButton, IonCardTitle, IonToolbar, IonHeader, IonBackButton, IonContent } from "@ionic/react";
import { supabase } from "../../hooks/supabase";
import { useEffect, useState } from "react";
import useUser from "../../hooks/user/getUser";
import { useHistory } from "react-router";
import TrainingCardFeed from "../../components/feed/TrainingCardFeed";
import LoadingCard from "../../components/loading/LoadingCard";
import HomeNav from "../Trainings/homeNav";

const Feed: React.FC = () => {
    const history = useHistory();
    const { user, setUser } = useUser();
    const [friends, setFriends] = useState([user.id]);
    const [trainings, setTrainings] = useState([]);
    const [loaded, setLoaded] = useState(false);
    console.log(friends);
    console.log(trainings);

    useEffect(() => {
        getFriends();
    }, [])

    async function setFriendIds(data) {
        data.map((followers) => {
            if (typeof followers.followee != 'undefined')
                setFriends(friends => [...friends, followers.followee])
            getFeed();
        })
    }

    async function getFriends() {
        try {
            const { data, error } = await supabase
                .from("follows")
                .select("followee")
                .eq('follower', user.id)
            console.log(data)
            setFriendIds(data)
        } catch (error) {

        }
    }

    async function getFeed() {
        try {
            const { data, error } = await supabase
                .from("trainings")
                .select(`
                id,
                location,
                date,
                time,
                trainer,
                dogs (
                    id,
                    name,
                    avatar_url
                ),
                training_type (
                    id,
                    type_name
                ),
                likes (
                    id,
                    liker_id
                ),
                profiles (
                    full_name,
                    avatar_url
                ),
                comments (
                    id, 
                    commenter_id,
                    profiles (
                        id,
                        full_name,
                        avatar_url
                    ),
                    content
                )
            

            `)
                .in('user_id', friends)
                .order('date', { ascending: false })
            console.log("getFeed")
            if (data) {
                console.log(data)
                setTrainings(data);

                setLoaded(true);
            }
        } catch (error) {

        }
    }
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <HomeNav />
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                {!loaded &&
                    <LoadingCard />
                }
                {loaded &&
                    <IonGrid>
                        {trainings.map((training) => (
                            <IonRow>
                                <IonCol>
                                    <TrainingCardFeed training={training} loaded={loaded} />

                                </IonCol>
                            </IonRow>
                        ))}
                    </IonGrid>
                }
            </IonContent>
        </IonPage>
    );
}


export default Feed;