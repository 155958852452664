import { IonButton, IonIcon } from "@ionic/react"
import { trashBinOutline } from "ionicons/icons"
import { useState } from "react"


const DeleteCommentButton: React.FC = (props) => {

    const commentId = props.commentId;
    // console.log(commentId);
    // const [comments, setComments] = useState(props.comments);

    // async function deleteComment(){
    //     console.log(commentId);
    //     console.log(comments);
    //     props.setComments((current) =>
    //     current.filter((comments) => comments.id === commentId)
    //     );
    //     console.log(comments)
    // }
    
    return (
        <IonButton 
        class="transparent"
        onClick={() => props.deleteComment(commentId)}>
            <IonIcon icon={trashBinOutline} />
        </IonButton>
    )
}

export default DeleteCommentButton;