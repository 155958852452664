import { IonRow, IonCol, IonItem, IonAvatar, IonLabel, IonModal, IonHeader, IonToolbar, IonButtons, IonButton, IonImg } from "@ionic/react";
import { useEffect, useState } from "react";
import Following from "../../pages/Profile/PublicProfile/FollowUser";
import FollowUser from "../../pages/Profile/PublicProfile/FollowUser";
import UnfollowUser from "../../pages/Profile/PublicProfile/UnfollowUser";


const PublicProfileCard: React.FC = (props) => {

  console.log(props);
  
  const profile = props.profile;
  const following = props.following;
  //const following = false;
  console.log(following);
  const [viewImage, setViewImage] = useState(false);

  
  return (
    <>
      <IonRow>
        <IonCol>
          <IonAvatar>
            <img src={profile.avatar_url} />
          </IonAvatar>
        </IonCol>
        <IonCol>
          <p>{profile.id}</p>
          <p>{profile.username}</p>
          <p>{profile.full_name}</p>
          <p>Followers: {props.followerAmount} | Following: {props.followingAmount}</p>
          
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          {!following &&
            <FollowUser following={props.following} setFollowing={props.setFollowing} connection={props.connection}/>
          }
          {
            following &&
            <UnfollowUser following={props.following} setFollowing={props.setFollowing} connection={props.connection} />
          }

        </IonCol>
      </IonRow>
      <IonModal isOpen={viewImage}>
    <IonHeader>
          <IonToolbar>
           
            <IonButtons slot="end">
              <IonButton onClick={() => {
                setViewImage(false)
              }}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      <IonImg src={profile.avatar_url}></IonImg>
    </IonModal>
    </>
  )
}

export default PublicProfileCard;