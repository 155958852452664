import {
    IonContent,
    IonGrid,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonRow, IonCol,
    IonButton,
    IonButtons,
    IonRefresher,
    IonRefresherContent,
    IonCard,
    IonAvatar,
    IonSkeletonText
} from '@ionic/react';
import '../../Trainings/Home.css';

import { useState, useEffect } from 'react'

import { supabase } from '../../../hooks/supabase';
import useUser from '../../../hooks/user/getUser';
import DogDetailCard from '../../../components/dogs/DogDetailCard';
import LoadingCard from '../../../components/loading/LoadingCard';
import ProfileNav from '../ProfileNav';
import ProfileCard from '../../../components/profile/ProfileCard';
import UpdateProfileForm from '../UpdateProfile/UpdateProfileForm';
import { useParams } from 'react-router';
import PublicProfileNav from './PublicProfileNav';
import PublicProfileCard from '../../../components/profile/PublicProfileCard';
import { useFollowing } from '../../../hooks/zustand/store';




const PublicProfile: React.FC = () => {
 
    const { user, setUser } = useUser();
    const [profile, setProfile] = useState();
    const [dogs, setDogs] = useState();
    const [loadedProfile, setLoadedProfile] = useState(false);
    const [loadedDogs, setLoadedDogs] = useState(false);
    const [following, setFollowing] = useState(false);
    const [connection, setConnection] = useState();
    const [followerAmount, setFollowerAmount] = useState("");
    const [ followingAmount, setFollowingAmount] = useState("");
    const params = useParams();
    const id = params.id;

    function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
        setTimeout(() => {
            getProfile();
            event.detail.complete();
        }, 2000);
    }


    useEffect(() => {
        getProfile();
        getFollowers();
        getFollowing();
        getDogs();
    }, [])


    async function getProfile() {
        try {
            const { data, error } = await supabase
                .from("profiles")
                .select(`
            id,
            username, 
            full_name,
            avatar_url
          `)
            .eq('id', params.id)
            if (error) throw error;
            if (data != null) {
                setProfile(data);
                console.log(data);
                setLoadedProfile(true);
                // setProfileToStore(data); 
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    async function getFollowers() {
       try {
        const { data, error } = await supabase
        .from("follows")
        .select(`
        id,
        followee,
        follower
        `)
        .or('followee.eq.'+id,'follower.eq.'+id)
        if(error) throw error;
        if(data.length != 0) {
            console.log(data);
            setFollowing(true);
            setConnection(data);
            
        }
        setFollowerAmount(data.length);
       } catch (error) {
        
       }
    }

    async function getFollowing() {
        try {
         const { data, error } = await supabase
         .from("follows")
         .select(`
         id,
         followee,
         follower
         `)
         .or('follower.eq.'+id)
         if(error) throw error;
         setFollowingAmount(data.length);
        } catch (error) {
         
        }
    }

    async function getDogs() {
        try {
            const { data, error } = await supabase
                .from("dogs")
                .select("*")
                .eq('user_id', params.id)
            if (error) throw error;
            if (data != null) {
                setDogs(data);
                console.log(data);
                setLoadedDogs(true);
            }
        } catch (error) {
            console.log(error.message);
        }
    }


    //console.log(profile[0])

    return (

        <IonPage>

            <IonHeader>
                <IonToolbar>
                    {!loadedProfile &&
                        <IonSkeletonText animated={true} style={{ 'width': '80px' }}></IonSkeletonText>
                    }
                    {loadedProfile &&
                        <PublicProfileNav />
                    }

                </IonToolbar>
            </IonHeader>
            
            <IonContent fullscreen>
                <IonRefresher slot="fixed" pullFactor={0.5} pullMin={100} pullMax={200} onIonRefresh={handleRefresh}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                    <IonGrid>
                        {!loadedProfile &&
                            <LoadingCard />
                        }
                        {loadedProfile &&
                            <IonCard>

                                <PublicProfileCard 
                                    profile={profile[0]} 
                                    following={following} 
                                    setFollowing={setFollowing} 
                                    connection={connection} 
                                    followerAmount={followerAmount}
                                    followingAmount={followingAmount} 
                                    />
                                {!loadedDogs &&
                                    <LoadingCard />
                                }
                                {loadedDogs &&
                                    <>
                                        <IonRow>

                                            {dogs.map((dog) => (
                                                <IonCol>
                                                    <DogDetailCard dog={dog} />
                                                </IonCol>
                                            ))}
                                        </IonRow>
                                    </>
                                }
                            </IonCard>
                        }
                    </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default PublicProfile;
