import React, { useState } from "react";
import {
  IonRow,
  IonCol,
  IonItem,
  IonInput,
  IonButton,
  IonModal,
  IonToolbar,
  IonButtons,
  IonHeader,
  IonTitle
} from "@ionic/react";
import { supabase } from "../../../hooks/supabase";
import useUser from "../../../hooks/user/getUser";
import { useParams } from "react-router";



const UpdateCommandForm: React.FC = (props) => {

  const [editing, setEditing] = useState();
  const [name, setName] = useState(props.command[0].name);
  const [description, setDescription] = useState(props.command[0].description);
  const params = useParams();
  const { user, setUser } = useUser();
  const [isOpen, setIsOpen] = useState(true);

  async function updateCommand() {
    try {
      const { data, error } = await supabase
        .from("commands")
        .update({
          dog_id: '9e35a081-e62e-4f16-b608-39e63381c46b',
          user_id: user.id,
          name: name,
          description: description,
        })
        .eq('id', params.id)
      if (error) throw error;
      console.log("inserted")
      window.location.reload();
    } catch (error) {
      console.log(error.message);
    }
  }

  return (
    <>
      <IonModal isOpen={isOpen}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Modal</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => {
                setIsOpen(false);
                setEditing(false)
              }}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonRow>
          <IonCol>
            <IonItem>
              {/* <IonLabel position="floating">Name</IonLabel> */}
              <IonInput
                label="Name"
                labelPlacement="stacked"
                name="name"
                clearInput={true}
                value={name}
                onIonChange={(e: any) => {
                  console.log(e.detail.value)
                  setName(e.detail.value);
                  console.log(name);
                }}



              >
              </IonInput>
            </IonItem>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            <IonItem>
              <IonInput
                type="text"
                name="description"
                clearInput={true}
                value={description}
                onIonChange={(e: any) => {
                  console.log(e.detail.value)
                  setDescription(e.detail.value);
                  console.log(description);
                }}
              >
              </IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonButton expand="block" onClick={() => updateCommand()}>Send</IonButton>

          </IonCol>
        </IonRow>
      </IonModal>

    </>
  );
}

export default UpdateCommandForm;