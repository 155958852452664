import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon } from '@ionic/react';
import { barbellOutline, playCircle, searchOutline, walkOutline } from 'ionicons/icons';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import DogAvatarCard from '../../../components/dogs/DogAvatarCard';


function TrainingCard(props) {
  const history = useHistory();
  const training = props.training;
  //const trainingIcon = '{' + training.training_type.icon + '}';
//<IonIcon icon={trainingIcon} />
//console.log(trainingIcon);

const trainingIcon = searchOutline;


  return (
    <>
      <IonCard onClick={() => {
        history.push("/training/" + training.id)
      }}>

        <IonCardHeader>
          <IonCardTitle><IonIcon icon={trainingIcon} />{training.location}</IonCardTitle>
                </IonCardHeader>
        <IonCardContent>
          <div>Time: {training.time}</div>
          <div>Trainer: {training.trainer}</div>
          <div>Exercise: {training.training_type.type_name}</div>
          <div>Date: {training.date}</div>
          <DogAvatarCard dog={training.dogs} />
        </IonCardContent>

      </IonCard>
    </>

  );
}

export default TrainingCard;