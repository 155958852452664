import { useState } from "react";

export default function useUser() {
    const getUser = () => {
        const userObjectString = localStorage.getItem('sb-mtblpkbzvzpmargjnupr-auth-token');
        const userObject = JSON.parse(userObjectString);
        return userObject?.user;
    }

    const [user, setUser] = useState(getUser());

    const saveUser = userObject => {
        setUser(userObject.user);
    }

    return {
        setUser,
        user
    }
}