import { IonItem, IonAvatar, IonLabel, IonButton, IonInput} from "@ionic/react";
import { useState } from "react";
import { supabase } from "../../hooks/supabase";
import useUser from "../../hooks/user/getUser";
import DeleteCommentButton from "./DeleteCommentButton";

const CommentSection: React.FC = (props) => {

    console.log(props);
    const profile = JSON.parse(localStorage.getItem('profile'))

    const [showCommentInput, setShowCommentInput] = useState(false);
    const [comments, setComments] = useState(props.training.comments);
    const [trainingId, setTrainingId] = useState(props.training.id);
    const [commentId, setCommentId] = useState();
    const {user, setUser} = useUser();
    const [commentContent, setCommentContent] = useState();
    console.log(user);

    async function commentTraining(){
        try {
            const { data, error } = await supabase
            .from("comments")
            .upsert({
                training_id: trainingId,
                commenter_id: user.id,
                profile_id: user.id,
                content: commentContent
            })
            .select()
            console.log(data)
            //setCommentId(data[0].id)
            console.log(commentId)
            setComments(comments => [...comments, {
                id: data[0].id,
                content: data[0].content,
                commenter_id: data[0].commenter_id,
                profiles: {
                    id: profile.id,
                    full_name: profile.full_name,
                    avatar_url: profile.avatar_url
                }
            }])
        } catch (error) {
            console.log(error);
        }
        
    }

    async function deleteComment(commentId) {
  

        setComments((current) =>
        current.filter((comments) => comments.id !== commentId)
        );
        try {
            const { data, error } = await supabase
            .from("comments")
            .delete()
            .eq('id', commentId);
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <>
        <IonButton onClick={() => setShowCommentInput(true)}>Comment</IonButton>
        {comments.map((comments) => (
            <IonItem>
                <IonAvatar>
                    <img src={comments.profiles.avatar_url} />
                </IonAvatar>
                <IonLabel>
                    {comments.profiles.full_name}: {comments.content}
                </IonLabel>
                <DeleteCommentButton commentId={comments.id} deleteComment={deleteComment}/>
            </IonItem>
        ))}
        {!showCommentInput &&
        <></>
        }
        {showCommentInput &&
              <IonItem>
              <IonInput
                  type="text"
                  placeholder="Comment training"
                  onIonChange={(e: any) => {
                    setCommentContent(e.detail.value);
                  }}
              />
              <IonButton 
                onClick={() => {
                commentTraining()
              }}>Comment</IonButton>
          </IonItem>
        }
      
        </>
    )
}

export default CommentSection;