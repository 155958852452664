import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonButton, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonInput, IonLabel, IonItem, IonCardSubtitle, IonIcon } from '@ionic/react';
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { useEffect, useState } from 'react';
import { useHistory } from "react-router";
import { supabase } from "../../hooks/supabase";
import { ReactPropTypes } from 'react';
import { logoGithub, logoGoogle } from 'ionicons/icons';

function Login() {

  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [user, setUser] = useState({});

  async function emailLogin() {
    const { data, error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    })
    
  }

  async function googleLogin() {

    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: 'http://localhost:8100'
      }
    })

  }

  console.log(process.env.REACT_APP_VERSION);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Login</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="ion-padding ion-text-center">
        <IonGrid>
          <IonRow>
            <IonCol>
              {/* <Auth
                supabaseClient={supabase}
                appearance={{ theme: ThemeSupa }}
                theme="dark"
                providers={["apple", "google"]}
              /> */}
              {/* <IonButton onClick={() => googleLogin()}>
                Google
              </IonButton> */}
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    Login to dogtrack.io
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                <IonItem>
                  <IonLabel 
                    position='floating'
                  >
                    Email
                    </IonLabel>
                  <IonInput
                    name="email"
                    clearInput={true}
                    value={email}
                    onIonChange={(e: any) => {
                      console.log(e.detail.value)
                      setEmail(e.detail.value);
                    }}
                  >
                  </IonInput>
            </IonItem>

                <IonItem>
                <IonLabel 
                    position='floating'
                  >
                    Password</IonLabel>
                  <IonInput
                    name="name"
                    type='password'
                    clearInput={true}
                    value={password}
                    onIonChange={(e: any) => {
                      console.log(e.detail.value)
                      setPassword(e.detail.value);
                    }}
                  >
                  </IonInput>
            </IonItem>
            <IonButton 
              expand="block"
              onClick={() => emailLogin()}>Login</IonButton>  

                </IonCardContent>
                <IonCardContent>
                  <IonCardSubtitle>
                    Or login using 
                  </IonCardSubtitle>
                  <IonCol>
                    <IonButton
                      onClick={() => googleLogin()}
                    ><IonIcon icon={logoGoogle} /> Google</IonButton>
                  </IonCol>
                  <IonCol>
                    <IonButton><IonIcon icon={logoGithub} /> Github</IonButton>
                  </IonCol>
                </IonCardContent>
                <IonCardContent>
                  <div>{process.env.REACT_APP_VERSION}</div>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonRow>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default Login;