import { IonButton } from "@ionic/react";
import { useState } from "react";
import { supabase } from "../../../hooks/supabase";
import { useParams } from "react-router";
import useUser from "../../../hooks/user/getUser";
import { useProfile } from "../../../hooks/zustand/store";

const FollowUser: React.FC = (props) => {
    console.log(props);
    const params = useParams();
    const { user, setUser } = useUser();

    async function followUser() {
        try {
            const { data, error } = await supabase
            .from("follows")
            .insert({
                followee: params.id,
                follower: user.id
            })  
            if(error) throw error;
            console.log(data)
            props.setFollowing(true)
        } catch (error) {
            console.log(error)
        }
    }

    if ( params.id != user.id ) {
    return (
        <IonButton onClick={() => (
            followUser()
        )}>
            Follow
        </IonButton>

    );
        }
};

export default FollowUser;