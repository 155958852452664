import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar, IonPage, IonContent, IonItem, IonLabel, IonInput, useIonModal, IonBackButton, useIonActionSheet } from '@ionic/react';
import { create, ellipsisHorizontal, ellipsisVertical, helpCircle, search, star, peopleOutline, logOutOutline, addCircleOutline } from 'ionicons/icons';
import { OverlayEventDetail } from '@ionic/core/components';



function CommandDetailsNav(props) {

  //const title = props.title;
  console.log(props);
  const [present] = useIonActionSheet();
  const [result, setResult] = useState<OverlayEventDetail>();
  const history = useHistory();
  const command = props.command;


  return (
    <IonToolbar>
      <IonButtons slot="start">
        <IonBackButton defaultHref="/commands"></IonBackButton>
      </IonButtons>
      <IonTitle>{command.name}</IonTitle>
      <IonButtons slot="primary">
        <IonButton
          onClick={() =>
            present({
              header: 'Example header',
              subHeader: 'Example subheader',
              buttons: [
                {
                  text: 'Delete',
                  role: 'destructive',
                  data: {
                    action: 'delete',
                  },
                },
                {
                  text: 'Update',
                  data: {
                    action: 'update',
                  },
                },
                {
                  text: 'Cancel',
                  role: 'cancel',
                  data: {
                    action: 'cancel',
                  },
                },
              ],
              onDidDismiss: ({ detail }) => {
                console.log(detail);
                if (detail.data.action === 'delete') {
                  console.log(detail.data.action);
                  props.deleteCommand();
                }

                if (detail.data.action === 'update') {
                  console.log("Update")
                  props.setEditing(true);
                }
              },
            })
          }
        >
          <IonIcon slot="icon-only" icon={ellipsisHorizontal}></IonIcon>
        </IonButton>

      </IonButtons>
    </IonToolbar>

  );
}
export default CommandDetailsNav;