import {
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRow, IonCol,
  IonButton,
  IonButtons,
  IonRefresher,
  IonRefresherContent,
  IonCard,
  IonAvatar,
  IonSkeletonText,
  IonBackButton
} from '@ionic/react';
import '../Trainings/Home.css';

import { useState, useEffect } from 'react'

import HomeNav from '../Trainings/homeNav';

import { supabase } from '../../hooks/supabase';
import useUser from '../../hooks/user/getUser';
import DogAvatarCard from '../../components/dogs/DogAvatarCard';
import DogDetailCard from '../../components/dogs/DogDetailCard';
import LoadingCard from '../../components/loading/LoadingCard';

import DogNav from './DogNav';
import { useHistory, useParams } from 'react-router';
import UpdateDogForm from './UpdateDog/UpdateDogForm';
import UpdateDogProfilePicture from './UpdateDog/UpdateDogPicture';


const Dog: React.FC = () => {
  const history = useHistory();
  const { user, setUser } = useUser();
  const [dog, setDog] = useState();
  const [loadedDog, setLoadedDog] = useState(false);
  const [editing, setEditing] = useState(false)
  const [updateProfilePicture, setUpdateProfilePicture] = useState(false);
  const params = useParams();

  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
      getDog();
      event.detail.complete();
    }, 2000);
  }



  useEffect(() => {
    getDog();
  }, [])

  async function deleteDog() {
    try {
      const { data, error } = await supabase
        .from("dogs")
        .delete()
        .eq('id', params.id)
      if (error) throw error;
      history.push("/profile/" + user.id)
    } catch (error) {
      console.log(error.message);
    }
  }


  async function getDog() {
    try {
      const { data, error } = await supabase
        .from("dogs")
        .select("*")
        .eq('id', params.id)
      if (error) throw error;
      if (data != null) {
        setDog(data);
        console.log(data);
        setLoadedDog(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  }


  //console.log(profile[0])

  return (

    <IonPage>

      <IonHeader>
        <IonToolbar>
          {!loadedDog &&
           <IonBackButton />
           // <IonSkeletonText animated={true} style={{ 'width': '80px' }}></IonSkeletonText>
          }
          {loadedDog &&
            <DogNav dog={dog[0]} setEditing={setEditing} deleteDog={deleteDog} setUpdateProfilePicture={setUpdateProfilePicture} />
          }

        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonRefresher slot="fixed" pullFactor={0.5} pullMin={100} pullMax={200} onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        {!editing &&
          <IonGrid>
            {!loadedDog &&
              <LoadingCard />
            }
            {loadedDog &&
              <IonCard>
                <DogDetailCard dog={dog[0]} />

              </IonCard>
            }

          </IonGrid>
        }
        {editing &&

          <UpdateDogForm editing={editing} dog={dog} />
        }
        {!updateProfilePicture &&
          <></>
        }
        {updateProfilePicture &&
          <UpdateDogProfilePicture setUpdateProfilePicture={setUpdateProfilePicture} user={user} dog={dog} />  
        }
        
      </IonContent>
    </IonPage>
  );
};

export default Dog;
